import React, { useEffect } from 'react';
import { CostTableRowData, CostsResult, OrderItem } from '../../pages/Costs';
import CostRow from './costRow';
import StoresSelect from './StoresSelect';
import { ProductInterface } from '../../pages/Products';
import SingleStoreTable from './SingleStoreTable';

const StoreSection = (props: {
  stores: CostsResult[];
  products: ProductInterface[];
}) => {
  const { stores } = props;
  const [selectedStore, setSelectedStore] = React.useState<string | number>(
    stores[0].storeId
  );

  const [processedStores, setProcessedStores] = React.useState<
    ProcessedStore[]
  >([]);

  const matchCostToItem = (sku: string): number => {
    let cost = 0;
    props.products.forEach((product) => {
      if (product.sku === sku) {
        cost = product.cost;
      }
    });
    return cost;
  };

  const processSingleStore = (store: CostsResult): CostTableRowData[] => {
    //SORT ORDER ITEMS BY SKU

    const result: CostTableRowData[] = [];
    const uniqueSkus: string[] = [];
    const itemsFromAllOrders: OrderItem[] = [];
    store.orders.forEach((order) => {
      if (order.status === 'processing') {
        /* if (order.recurring === true) {
        } else { */
        order.items.forEach((item) => {
          if (!uniqueSkus.includes(item.sku)) {
            uniqueSkus.push(item.sku);
          }
          itemsFromAllOrders.push(item);
        });
        /* } */
      }
    });
    uniqueSkus.forEach((sku) => {
      let itemsSold = 0;
      let inOrders = 0;
      let totalRevenue = 0;

      itemsFromAllOrders.forEach((item) => {
        if (item.sku === sku) {
          itemsSold += item.quantity;
          inOrders++;

          const convertedTotal = item.total; /* * item.quantity */

          totalRevenue += convertedTotal;
        }
      });
      const costPerItem = matchCostToItem(sku);
      const totalCost = costPerItem * itemsSold;

      result.push({
        sku,
        itemsSold,
        inOrders,
        totalRevenue: totalRevenue,
        costPerItem,
        totalCost: parseFloat(totalCost.toFixed(2)),
        ordersCount: store.orders.filter(
          (order) => order.status === 'processing' && order.recurring === false
        ).length,
      });
    });

    //GET TOTAL AMOUNT OF ITEMS IN ALL ORDERS (orderCount*quantity per order)
    //GET NUMBER OF ORDERS IN WHICH ITEM WAS BOUGHT
    //SET COLUMN FOR COST OF AN ITEM -> input
    //SET ON COST INPUT CHANGE CALCULATE TOTAL COST OF ITEMS

    return result;
  };

  const processTotals = (data: CostTableRowData[]): CostTableRowData => {
    const result = {
      sku: 'Total',
      itemsSold: 0,
      inOrders: 0,
      totalRevenue: 0,
      costPerItem: 0,
      totalCost: 0,
    };

    data.forEach((item) => {
      if (!item.totalCost) item.totalCost = 0;
      result.itemsSold += item.itemsSold;
      result.inOrders += item.inOrders;
      result.totalRevenue += item.totalRevenue;
      result.totalCost += item.totalCost;
    });

    result.costPerItem = 0;
    result.totalRevenue = parseFloat(result.totalRevenue.toFixed(2));
    result.costPerItem = parseFloat(
      (result.totalCost / result.itemsSold).toFixed(2)
    );
    result.totalCost = parseFloat(result.totalCost.toFixed(2));
    return result;
  };

  const processAllStores = (): ProcessedStore[] => {
    const result: ProcessedStore[] = [];
    stores.forEach((store) => {
      store.orders = store.orders.filter(
        (order) => order.status === 'processing'
      );

      /* store.orders.filter((order) => order.recurring === false); */
      const storeData = processSingleStore(store);
      result.push({
        storeName: store.storeName,
        storeId: store.storeId,
        data: storeData,
        totals: processTotals(storeData),
        ordersCount: store.orders.length,
      });
    });
    return result;
  };

  useEffect(() => {
    setProcessedStores(processAllStores());
  }, []);
  return (
    <div
      className="table-container input-super-conatiner"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h2>Prikaz po trgovinah</h2>
      <div
        className="store-selection"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <h4>Izberi trgovino:</h4>
        <StoresSelect
          stores={stores}
          setStore={setSelectedStore}
          initial={stores[0].storeId}
        />
      </div>
      {processedStores.map((store) => {
        return (
          <SingleStoreTable
            store={store}
            display={store.storeId == selectedStore}
            ordersCount={store.ordersCount}
            key={store.storeId}
          />
        );
      })}
    </div>
  );
};

export default StoreSection;
export interface ProcessedStore {
  storeName: string;
  storeId: string | number;
  data: CostTableRowData[];
  totals: CostTableRowData;
  ordersCount?: number;
}
