import './style/main.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Analytics from './pages/Analytics';
import Navbar from './components/Navbar';
import LoginPage from './pages/Login';
import ProtectedRoute from './ProtectedRoute';
import { useEffect, useState } from 'react';
import StoresPage from './pages/StoresPage';
import CreateCurrencyPage from './pages/CreateCurrencyPage';
import UpdateCurrencyPage from './pages/UpdateCurrencyPage';
import AdminProtectedRoute from './AdminProtectedRoute';
import CostsPage from './pages/Costs';
import ProductsPage from './pages/Products';
import NotFound from './pages/NotFound';

function App() {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    const token = localStorage.getItem('AccessToken');
    if (token) {
      setLoggedIn(true);
    }
    document.title = 'Provitalis Analitika';
  }, []);
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar loggedIn={loggedIn} />
        <Routes>
          <Route
            path="/"
            element={
              <LoginPage loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
            }
          />
          <Route element={<ProtectedRoute />}>
            <Route path="/analytics" element={<Analytics />} />

            <Route path="/create-store" element={<StoresPage />} />
            <Route path="/create-currency" element={<CreateCurrencyPage />} />
            <Route path="/update-currency" element={<UpdateCurrencyPage />} />
          </Route>
          <Route element={<AdminProtectedRoute />}>
            <Route path="/costs" element={<CostsPage />} />
            <Route path="/products" element={<ProductsPage />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
