import CostRow from './costRow';
import { CostTableRowData } from '../../pages/Costs';

const TotalsTable = (props: {
  allItemsCombined: CostTableRowData[];
  totalCost: number;
  totals: CostTableRowData;
}) => {
  return (
    <table>
      <thead>
        <tr>
          <th>SKU</th>
          <th>Items Sold</th>
          <th>In Orders</th>
          <th>Total Revenue</th>
          <th>Cost Per Item</th>
          <th>Total Cost</th>
          <th>Profit</th>
        </tr>
      </thead>
      <tbody>
        {props.allItemsCombined.map((item: CostTableRowData) => {
          return (
            <CostRow
              key={item.sku}
              item={item}
              totalCost={item.totalCost || 0}
            />
          );
        })}
        <CostRow item={props.totals} totalCost={props.totals.totalCost || 0} />
      </tbody>
    </table>
  );
};

export default TotalsTable;
