const SummaryTable = (props: {
  totalCost: number;
  totalRevenue: number;
  totalItems: number;
  totalPackages: number;
}) => {
  return (
    <table className={'summary-table'} style={{ textAlign: 'center' }}>
      <tr>
        {/* <th>SKU's Sold</th> */}
        <th>Total Cost</th>
        <th>Stevilo Paketov</th>
        <th>Average Cost</th>
        <th>Total Revenue</th>
        <th>Gross Profit</th>
      </tr>
      <tr>
        {/*  <td>{props.totalItems}</td> */}
        <td>{props.totalCost.toFixed(2)}</td>
        <td>{props.totalPackages}</td>
        <td>{(props.totalCost / props.totalPackages).toFixed(2)}</td>
        <td>{props.totalRevenue}</td>
        <td>{(props.totalRevenue - props.totalCost).toFixed(2)}</td>
      </tr>
    </table>
  );
};

export default SummaryTable;
