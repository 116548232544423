import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { proxy } from '../env/proxy';
import EmailInput from '../components/login/email-input';
import OtpInput from '../components/login/otp-input';

const LoginPage = (props: { loggedIn: boolean; setLoggedIn: any }) => {
  const [email, setEmail] = useState<string>('');
  const [otpRequested, setOtpRequested] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>('');
  const [btn, setBtn] = useState<boolean>(false);
  const [otpBtn, setOtpBtn] = useState<boolean>(false);
  const [loginErr, setLoginErr] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('AccessToken');
    if (token) {
      navigate('/analytics');
    }
    btnGuard();
  }, []);

  const btnGuard = () => {
    if (email && email.length > 5) {
      setBtn(true);
    } else {
      setBtn(true);
    }
  };
  const onEmailChange = (e: any) => {
    setEmail(e.target.value);
    localStorage.setItem('email', e.target.value);
    btnGuard();
  };

  const onOtpChange = (e: any) => {
    setOtp(e.target.value);
    otpBtnGuard();
  };

  const otpBtnGuard = () => {
    if (otp && otp.length > 4) {
      setOtpBtn(true);
    } else {
      setOtpBtn(false);
    }
  };

  const onOTPPaste = (event: any) => {
    // Allow the paste operation to complete, then access the updated input value
    setTimeout(() => {
      const newValue = event.target.value; // Access the updated value after paste
      setOtp(newValue);
      otpBtnGuard();
    }, 1);
  };

  const requestOtp = async () => {
    try {
      setLoginErr(null);
      setLoading(true);
      const url = `${proxy.url}/login/request-login-otp`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
      });
      const resData = await response.json();
      setLoading(false);
      if (!resData || resData.success != 1) {
        setLoginErr('Please enter a valid email address');
      } else {
        setOtpRequested(true);
      }
      /* if (!resData || resData.success == 0) {
        setLoginErr(true);
      } else {
        localStorage.setItem("AccessToken", resData.creds);
        props.setLoggedIn(true);
        navigate("/optimize");
      } */
    } catch (error) {
      setLoading(false);
      setLoginErr('Please enter a valid email address');
    }
  };

  const submitOtp = async () => {
    try {
      setLoginErr(null);
      const url = `${proxy.url}/login/login-v2`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          otp,
        }),
      });
      const resData = await response.json();
      console.log(resData);
      if (resData.error) {
        setLoginErr('Invalid Login Code');
      } else {
        localStorage.setItem('AccessToken', resData.token);
        localStorage.setItem('UserData', JSON.stringify(resData.user));
        props.setLoggedIn(true);
        navigate('/analytics');
      }
    } catch (error) {
      setLoginErr('Invalid Login Code');
    }
  };
  if (loading) {
    return (
      <div className="loading-div">
        <img src="/logo512.png"></img>
        Loading
      </div>
    );
  } else {
    return (
      <div className="login-content">
        {loginErr ? <div className="login-error">{loginErr}</div> : null}
        <div className="login-form-container">
          <h1>Provitalis</h1>{' '}
          {!otpRequested ? (
            <EmailInput
              onEmailChange={onEmailChange}
              btn={btn}
              requestOtp={requestOtp}
            />
          ) : (
            <OtpInput
              onOtpChange={onOtpChange}
              otpBtn={otpBtn}
              submitOtp={submitOtp}
              setLoginError={setLoginErr}
              onOTPPaste={onOTPPaste}
            />
          )}
          {/*CREATE LOADING OVERLAY*/}
        </div>
      </div>
    );
  }
};

export default LoginPage;
